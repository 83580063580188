<template>
  <div class="market">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="market__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div>
      <div class="market__intro" v-if="isMarketPage && $route.name !== 'RewardDetails'">
        <div class="market__info">
          <div
            class="info__image"
            :style="`background-image: url('${getRewardsCover}');`"
          ></div>
          <div class="info__text">
            <div class="info__title" v-html="getRewardsTitle"></div>
            <div class="info__subtitle" v-html="getRewardsSubtitle"></div>
            <div class="info__note" v-html="getRewardsNote"></div>
          </div>
        </div>
      </div>
      <div v-if="isMarketPage && $route.name !== 'RewardDetails'" class="market__category" :class="{ length: categoryOne && categoryOne.length >= 4 }">
        <div
          class="market__category-item"
          v-for="category in categoryOne"
          :key="category.id"
          @click.stop="onCategoryClick(category)"
        >
          <img :src="category.imageUrl" alt="">
          <p class="market__category-name">{{ category.name }}</p>
        </div>
      </div>
    </div>
    <div v-if="$route.name !== 'RewardDetails'">
      <div
        v-if="isMarketCategoryPage"
        class="market__intro_category"
        :style="`background-image: url('${marketImage}');`"
      >
        <Link
          v-if="!isMobileApp"
          class="market__back"
          variant="secondary"
          text="Разделы маркета"
          iconLeft="arrow-left"
          @click.prevent="onBackToMarketRoot"
        />
        <div v-if="isMobileApp"></div>
        <p class="market__title">{{ marketTitle }}</p>
      </div>
      <div v-if="isMarketCategoryPage && disciplines.length > 0" class="market__disciplines">
        <div class="market__discipline"
        :class="{ active: activeDisciplineId === discipline.categoryTreeNodeId }"
        :style="getBackgroundImage(discipline)"
        v-for="discipline in disciplines"
        :key="discipline.categoryTreeNodeId"
        @click="onDisciplineClick(discipline)"
      >
          <p class="market__discipline-name">{{ discipline.name }}</p>
        </div>
      </div>
      <div class="market__promotions">
        <div class="market__overlay" v-if="updating">
          <Loader size="24"/>
        </div>
        <div v-if="showMarketFilters" class="divider"></div>
        <Filters
          v-if="showMarketFilters && isFiltersLoaded"
          :options="filters"
          @change="onFilterChange"
          @reset="onFilterReset"
        />
        <div class="market__empty" v-if="isFiltersLoaded && (promotions === null || promotions.count === 0) && items.length === 0 && isMarketPage">
          <div class="text-center">Ничего не найдено.</div>
        </div>
        <div
          class="market__items"
          :class="{ 'market__items_no-sorting': !showMarketSorting }"
          v-if="items.length > 0"
        >
          <div>
            <Sort
              v-if="showMarketSorting"
              :items="sortItems"
              @selected="onSortItem"
            />
          </div>
          <div>
            <RewardCard
              v-for="(promotion, i) in items"
              :key="i"
              :image="promotion.images ? ($root.isMobile ? promotion.images.mobile : promotion.images.desktop) : ''"
              :action="{ func: () => onAction(i) }"
              type='coins'
              :state="isSmallQuantity(promotion) ? 'count' : ''"
              :stateLabels="{ available: 'В наличии', count: `${promotion.promocodeQty} шт.` }"
              :title="promotion.name"
              :subtitle="promotion.subTitle"
              :cost="promotion.priceInPoints"
              :compactMobile="$root.isMobile"
            ></RewardCard>
          </div>
          <div>
            <Button
              v-if="currentPage === 1 && totalPages > 1 && showMore"
              variant="secondary"
              size="lg"
              @click="onMore"
              class="market__more"
              mobile
              wide
            >
              Показать ещё
            </Button>
            <Pagination
              v-if="showPagination && totalPages > 1"
              :currentPage="currentPage"
              :totalPages="totalPages"
              class="market__pagination"
              @prev-page="onChangePage(currentPage - 1)"
              @next-page="onChangePage(currentPage + 1)"
              @go-page="onChangePage"
            ></Pagination>
          </div>
        </div>
      </div>

    </div>
    <div v-if="$route.name === 'RewardDetails' && !isMarketPage">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import RewardCard from '@sc-component-library/reward-card/src/main';
import Menu from '@/components/Menu/Menu.vue';
import Filters from '@/components/Filters/Filters.vue';
import Sort from '@/components/Sort/Sort.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import GetRewardsCover from '../assets/img/get-rewards/spend-coins.svg';

export default {
  name: 'Market',
  components: {
    RewardCard,
    Menu,
    Filters,
    Sort,
    Pagination,
  },
  data() {
    return {
      category: null,
      disciplines: [],
      activeDisciplineId: null,
      marketImage: '',
      marketTitle: '',
      isMarketCategory: true,
      items: [],
      currentPage: 1,
      itemsPerPage: 12,
      totalPages: 0,
      showMoreOffset: 0,
      showMore: true,
      showPagination: true,
      filters: {
        dateEvent: [],
        category: [],
        type: [],
        partner: [],
        price: [],
      },
      categoryTreeNodes: null,
      typeNames: [
        { id: 'Discount', name: 'Скидка 100%', order: 1 },
        { id: 'Promocode', name: 'Скидка <100%', order: 2 },
        { id: 'Prize', name: 'Розыгрыш', order: 3 },
      ],
      activeFilters: {},
      isFiltersLoaded: false,
      sortItems: [
        { id: '-', text: 'В тренде', active: true },
        { id: 'createdOn', text: 'Новые' },
        { id: 'price', text: 'По цене', descending: false },
      ],
      months: [
        { id: 1, name: 'Январь' },
        { id: 2, name: 'Февраль' },
        { id: 3, name: 'Март' },
        { id: 4, name: 'Апрель' },
        { id: 5, name: 'Май' },
        { id: 6, name: 'Июнь' },
        { id: 7, name: 'Июль' },
        { id: 8, name: 'Август' },
        { id: 9, name: 'Сентябрь' },
        { id: 10, name: 'Октябрь' },
        { id: 11, name: 'Ноябрь' },
        { id: 12, name: 'Декабрь' },
      ],
      updating: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeDone', 'options', 'resources']),
    ...mapGetters('market', ['promotions', 'priceRange', 'promotionTypes', 'partners', 'categories', 'categoryOne', 'categoryTwo', 'categoryThree', 'categoryFour', 'breadcrumbs']),
    ...mapGetters('user', ['isMobileApp']),
    isMarketPage() {
      return this.$route.name === 'Market';
    },
    isMarketCategoryPage() {
      return this.$route.name === 'MarketCategoryOne' || this.$route.name === 'MarketCategoryTwo';
    },
    showOffset() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    showMarketFilters() {
      return this.options ? this.options.showMarketFilters === true : false;
    },
    enabledMarketFilters() {
      return this.options ? this.options.enabledMarketFilters || [] : [];
    },
    showMarketSorting() {
      return this.options ? this.options.showMarketSorting === true : false;
    },
    enabledMarketSorting() {
      return this.options ? this.options.enabledMarketSorting || [] : [];
    },
    getRewardsCover() {
      return this.resources ? this.resources.getRewardsCover || GetRewardsCover : GetRewardsCover;
    },
    getRewardsTitle() {
      return this.resources ? this.resources.getRewardsTitle || '' : '';
    },
    getRewardsSubtitle() {
      return this.resources ? this.resources.getRewardsSubtitle || '' : '';
    },
    getRewardsNote() {
      return this.resources ? this.resources.getRewardsNote || '' : '';
    },
    getBackgroundImage() {
      return (discipline) => ({
        backgroundImage: this.activeDisciplineId !== discipline.categoryTreeNodeId ? `linear-gradient(rgba(1, 1, 1, 0.45), rgba(1, 1, 1, 0.45)), url(${discipline.imageUrl})` : 'none',
      });
    },
    catOne() {
      return this.$route.params.catOne;
    },
    catTwo() {
      return this.$route.params.catTwo;
    },
    catThree() {
      return this.$route.query.category;
    },
    catFour() {
      return this.$route.params.subcategory;
    },
  },
  watch: {
    isMarketPage(val) {
      if (val) {
        this.loadPromotions();
      }
    },
    themeDone(val) {
      if (val) {
        this.setupFiltersAndSorting();
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('market', ['getPromotions', 'getPriceRange', 'getPromotionTypes', 'getPartners', 'getCategories', 'getCategoryOne', 'getCategoryTwo', 'getCategoryThree', 'getCategoryFour', 'getAllparentsCategory']),
    onAction(i) {
      if (this.isMobileApp) {
        const path = `/market/reward?id=${this.items[i].promotionId}&title=Маркет`;
        this.$root.$goPage('RewardDetails', encodeURIComponent(path));
        return;
      }
      this.$router.push({ name: 'RewardDetails', query: { id: this.items[i].promotionId } }).catch(() => {});
    },
    onChangePage(page) {
      this.currentPage = page;
      this.getPromotions({
        offset: this.showOffset, count: this.itemsPerPage, sort: this.getSort(), filters: this.getFilters(),
      })
        .then(() => {
          this.items = this.promotions ? this.promotions.values : [];
          this.totalPages = Math.ceil((this.promotions ? this.promotions.total : 0) / this.itemsPerPage);
        });
    },
    onMore() {
      this.showPagination = false;
      const showMoreOffset = this.showMoreOffset + this.itemsPerPage;
      if (showMoreOffset > this.promotions.total - 1) {
        this.currentPage = 0;
        this.showMore = false;
        return;
      }
      this.showMoreOffset = showMoreOffset;
      this.getPromotions({
        offset: this.showMoreOffset, count: this.itemsPerPage, sort: this.getSort(), filters: this.getFilters(),
      })
        .then(() => {
          this.items.push(...(this.promotions ? this.promotions.values : []));
          if (this.items.length >= this.promotions.total) this.showMore = false;
        });
    },
    onSortItem(index) {
      if (this.sortItems.length === 0) return;
      if (index >= 0 && index < this.sortItems.length) {
        const items = [];
        let needUpdate = false;
        for (let i = 0; i < this.sortItems.length; i += 1) {
          const item = this.sortItems[i];
          if (i !== index) {
            item.active = false;
            if (item.descending !== undefined) item.descending = false;
          } else if (item.active === true && item.descending !== undefined) {
            item.descending = !item.descending;
            needUpdate = true;
          } else if (item.active !== true) {
            item.active = true;
            needUpdate = true;
          }
          items.push(item);
        }
        this.sortItems = items;
        if (needUpdate) {
          this.items = [];
          this.currentPage = 1;
          this.itemsPerPage = 12;
          this.totalPages = 0;
          this.showMoreOffset = 0;
          this.showMore = true;
          this.showPagination = true;
          this.getPromotions({
            offset: this.showOffset, count: this.itemsPerPage, sort: this.getSort(), filters: this.getFilters(),
          })
            .then(() => {
              this.items = this.promotions ? this.promotions.values : [];
              this.totalPages = Math.ceil((this.promotions ? this.promotions.total : 0) / this.itemsPerPage);
            });
        }
      }
    },
    onFilterChange(filters) {
      const result = {};
      const keys = Object.keys(filters);
      for (let i = 0; i < keys.length; i += 1) {
        if (filters[keys[i]].val !== null) result[keys[i]] = filters[keys[i]];
      }
      this.activeFilters = result;
      this.loadPromotions();
    },
    onFilterReset() {
      this.activeFilters = {};
      this.loadPromotions();
    },
    setupFiltersAndSorting() {
      if (this.enabledMarketFilters) {
        const keys = Object.keys(this.filters);
        for (let i = 0; i < keys.length; i += 1) {
          if (!this.enabledMarketFilters.includes(keys[i])) {
            delete this.filters[keys[i]];
          }
        }
      }
      if (this.enabledMarketSorting) {
        for (let i = 0; i < this.sortItems.length; i += 1) {
          if (!this.enabledMarketSorting.includes(this.sortItems[i].id)) {
            this.sortItems[i].remove = true;
          }
        }
        const items = this.sortItems.filter((i) => i.remove !== true);
        if (items.length > 0 && items.filter((i) => i.active === true).length === 0) {
          items[0].active = true;
        }
        this.sortItems = items;
      }
    },
    loadPromotions() {
      this.items = [];
      this.currentPage = 1;
      this.itemsPerPage = 12;
      this.totalPages = 0;
      this.showMoreOffset = 0;
      this.showMore = true;
      this.showPagination = true;
      this.getPromotions({
        offset: 0, count: this.itemsPerPage, sort: this.getSort(), filters: this.getFilters(),
      })
        .then(() => {
          this.items = this.promotions ? this.promotions.values : [];
          this.totalPages = Math.ceil((this.promotions ? this.promotions.total : 0) / this.itemsPerPage);
          setTimeout(() => {
            this.updating = false;
          }, 500);
        });
    },
    isSmallQuantity(reward) {
      if (!reward) return false;
      return reward.promocodeQty <= 5;
    },
    setCategoryTreeNodes() {
      if (this.filters.categoryTreeNodes) {
        const filters = [];
        for (let i = 0; i < this.categories.length; i += 1) {
          filters.push({ id: this.categories[i].categoryId, name: this.categories[i].name });
        }
        this.filters.categoryTreeNodes = filters;
      }
    },
    setDateEventFilter() {
      if (this.months) {
        const filters = [];
        for (let i = 0; i < this.months.length; i += 1) {
          filters.push({ id: this.months[i].id, name: this.months[i].name });
        }
        this.filters.dateEvent = filters;
      }
    },
    setCategoriesFilter() {
      if (this.category && this.filters.category) {
        const filters = [];
        const categoryKeys = Object.keys(this.category);
        for (let i = 0; i < categoryKeys.length; i += 1) {
          const key = categoryKeys[i];
          const categoryItem = this.category[key];
          filters.push({
            id: categoryItem.categoryTreeNodeId,
            name: categoryItem.name,
          });
        }
        this.filters.category = filters;
      }
    },
    setTypeFilter() {
      if (this.promotionTypes && this.filters.type) {
        let filters = [];
        for (let i = 0; i < this.promotionTypes.length; i += 1) {
          const itemName = this.typeNames.find((item) => item.id === this.promotionTypes[i]);
          const name = itemName ? itemName.name : this.promotionTypes[i];
          filters.push({ id: this.promotionTypes[i], name, order: itemName.order });
        }
        filters = filters.sort(this.sortItemsByOrder);
        this.filters.type = filters;
      }
    },
    setPartnerFilter() {
      if (this.partners && this.filters.partner) {
        for (let i = 0; i < this.partners.length; i += 1) {
          this.filters.partner.push({ id: this.partners[i].partnerId, name: this.partners[i].name });
        }
      }
    },
    setPriceFilter() {
      if (this.priceRange && this.filters.price) {
        const max = this.priceRange.max !== undefined ? this.priceRange.max : 100;
        this.filters.price.push({ id: 'min', name: 'min', value: 1 });
        this.filters.price.push({ id: 'max', name: 'max', value: max });
        this.filters.price.push({ id: 'from', name: 'from', value: 1 });
        this.filters.price.push({ id: 'to', name: 'to', value: max });
      }
    },
    getSort() {
      const result = {};
      const found = this.sortItems.filter((i) => i.active === true);
      if (found.length === 0) return {};
      if (found[0].id !== '-') result.sortBy = found[0].id;
      if (found[0].id === 'createdOn') result.descending = true;
      if (found[0].descending !== undefined) result.descending = found[0].descending === true;
      return result;
    },
    getFilters() {
      const result = {};
      if (this.catThree || this.catTwo || this.catOne) {
        result.categoryTreeNodes = this.catThree || this.catTwo || this.catOne;
      }
      if (this.activeFilters.category) {
        result.categoryTreeNodes = this.activeFilters.category.val || [];
      }
      if (this.activeFilters.type) {
        result.type = this.activeFilters.type.val || [];
      }
      if (this.activeFilters.partner) {
        result.partnerId = this.activeFilters.partner.val || '';
      }
      if (this.activeFilters.price) {
        result.priceFrom = this.activeFilters.price.val.from || '';
        result.priceTo = this.activeFilters.price.val.to || '';
      }
      if (this.activeFilters.dateEvent) {
        result.dateFrom = this.getDateFromMonth(this.activeFilters.dateEvent.val) || '';
      }
      return result;
    },
    getDateFromMonth(monthNumber) {
      const newMonthNumber = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
      const currentYear = dayjs().year();
      const date = dayjs.utc(`${currentYear}-${newMonthNumber}-01`);
      return date.format('YYYY-MM-DDT00:00:00.000000+00:00');
    },
    onBackToMarketRoot() {
      this.marketImage = null;
      this.marketTitle = null;
      this.setCategoriesFilter();
      this.isFiltersLoaded = false;
      this.$router.push({ name: 'Market' }).catch(() => {});
      setTimeout(() => {
        this.isFiltersLoaded = true;
      }, 100);
    },
    sortItemsByOrder(a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    },
    getBreadcrumbs() {
      const categoryTreeNodeId = this.$route.params.catOne ? this.$route.params.catOne : null;
      const activeDiscipline = this.$route.params.catTwo ? this.$route.params.catTwo : null;
      if (!categoryTreeNodeId) return;
      this.getAllparentsCategory(categoryTreeNodeId)
        .then(() => {
          this.marketImage = this.breadcrumbs ? this.breadcrumbs[0].imageUrl : null;
          this.marketTitle = this.breadcrumbs ? this.breadcrumbs[0].name : null;
          this.activeDisciplineId = activeDiscipline;
        });
    },
    getDisciplines() {
      this.getCategoryTwo(this.$route.params.catOne)
        .then(() => {
          this.getBreadcrumbs();
          this.disciplines = this.categoryTwo;
          this.loadPromotions();
        });
    },
    onCategoryClick(category) {
      this.categoryTreeNodes = category.categoryTreeNodeId;
      if (this.isMobileApp) {
        const path = `/market/${this.categoryTreeNodes}?category=${category.categoryTreeNodeId}&title=Маркет`;
        this.$root.$goPage('MarketCategoryOne', encodeURIComponent(path));
      } else {
        this.$router.push({ name: 'MarketCategoryOne', params: { catOne: this.categoryTreeNodes }, query: { category: category.categoryTreeNodeId } }).catch(() => {});
      }
      this.getDisciplines();
      this.category = [];
    },
    onDisciplineClick(discipline) {
      this.categoryTreeNodes = discipline.categoryTreeNodeId;
      const currentPosition = window.scrollY;
      if (this.activeDisciplineId === this.categoryTreeNodes) {
        this.activeDisciplineId = null;
        this.category = [];
        this.isFiltersLoaded = false;
        this.setCategoriesFilter();
        this.$router.replace({
          name: 'MarketCategoryOne',
          params: { catOne: this.$route.params.catOne },
          query: { category: this.$route.params.catOne },
        }).then(() => {
          window.scrollTo(0, currentPosition);
        }).catch(() => {});
        this.getCategoryTwo(this.$route.params.catOne)
          .then(() => {
            this.isFiltersLoaded = true;
            this.loadPromotions();
          });
      } else {
        this.updating = true;

        this.activeDisciplineId = this.categoryTreeNodes;
        this.isFiltersLoaded = false;
        this.getCategoryThree(this.categoryTreeNodes)
          .then(() => {
            this.category = this.categoryThree;
            this.setCategoriesFilter();
            this.updating = true;
            this.isFiltersLoaded = true;
            this.loadPromotions();
          });
        this.$router.replace({
          name: 'MarketCategoryTwo',
          params: { catOne: this.$route.params.catOne, catTwo: this.categoryTreeNodes },
          query: { category: this.categoryTreeNodes },
        }).then(() => {
          window.scrollTo(0, currentPosition);
        }).catch(() => {});
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'Market') {
      this.isMarketCategory = true;
      this.activeDisciplineId = null;
      this.filters.category = [];
      this.category = [];
      this.getCategoryOne();
    }
    next();
  },
  mounted() {
    this.getProfile();
    this.getBalance();
    this.getCategoryOne();
    if (this.themeDone) {
      this.setupFiltersAndSorting();
    }
    if (this.catOne) {
      const id = this.activeDisciplineId ? this.activeDisciplineId : this.catThree;
      this.isFiltersLoaded = false;
      this.getBreadcrumbs();
      this.getDisciplines();
      this.getCategoryThree(id)
        .then(() => {
          this.category = this.activeDisciplineId ? this.categoryThree : [];
          this.setCategoriesFilter();
        });
    }
    this.getPromotionTypes()
      .then(() => {
        this.setTypeFilter();
        this.setCategoryTreeNodes();
        this.getPartners()
          .then(() => {
            this.setPartnerFilter();
            this.getPriceRange()
              .then(() => {
                this.setPriceFilter();
                this.setDateEventFilter();
                this.isFiltersLoaded = true;
              });
          });
      });
  },
};
</script>

<style lang="scss" scoped>
  @import "./Market";
</style>
